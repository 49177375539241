import React from "react"
import { MDXProvider, Components } from "@mdx-js/react"
import MdxLink from "./mdxLink"
import Navigation from "./Navigation"
import { PageContext } from "ExtendiApp"
import { Footer } from "./Footer"
import SEO from "./SEO"
import Helmet from "react-helmet"
import { WebpMachine } from "webp-hero"
import { ScrollToTop } from "./ScrollToTop"

type LocaleCtx = Pick<PageContext, "locale">
interface Props {
  pageContext: PageContext
}

const LocaleContext = React.createContext<LocaleCtx>({ locale: "it" })

// Use the built-in Context API to make the "locale" available to every component in the tree
// This e.g. enables the LocalizedLink to function correctly
// As this component wraps every page (due to the wrapPageElement API) we can be sure to have
// the locale available everywhere!
const Layout: React.FC<Props> = props => {
  const {
    children,
    pageContext: { locale, pageId },
  } = props

  console.log("ALL PAGE PROPS :", props)

  // ie11 polyfill
  if (typeof window === "object" && typeof window.document === "object") {
    const webpMachine = new WebpMachine()
    webpMachine.polyfillDocument()
  }

  return (
    <LocaleContext.Provider value={{ locale }}>
      <Helmet></Helmet>
      <SEO />
      <MDXProvider components={{ a: (MdxLink as any) as Components["a"] }}>
        <div className="body-inner">
          <Navigation activePageId={pageId} />
          <main>{children}</main>
          <Footer />
        </div>
        <ScrollToTop />
      </MDXProvider>
    </LocaleContext.Provider>
  )
}

export { Layout, LocaleContext }
